import $ from "jquery";
import "what-input";

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import "./lib/foundation-explicit-pieces";

// import Swiper from 'swiper/bundle';
// import Splide from '@splidejs/splide';
// import Splide from '@splidejs/splide/dist/js/splide';

$(document).foundation();

var engineUtils = {
  init: function () {
    this.ui.init();
  },

  ui: {
    init: function () {
      engineUtils.ui.layout();
      engineUtils.ui.plugins();
    },

    layout: function () {
      // Hero Dynamic Height
      if ($(".hero".length)) {
        $(".hero__img").height(window.innerHeight);
        $(window).on("resize", function (event) {
          $(".hero__img").height(window.innerHeight);
        });
      }

      if ($(".manga-hero".length)) {
        $(".manga-hero__img").height(window.innerHeight);
        $(window).on("resize", function (event) {
          $(".manga-hero__img").height(window.innerHeight);
        });
      }

      if ($(".menu-btn".length)) {
        $(".menu-btn").on("click", () => {
          $(".main-nav").toggleClass("active");
        });
      }

      if ($(".lang-switch__active".length)) {
        $(".lang-switch__active").on("click", () => {
          $(".lang-switch__list").toggleClass("expanded");
        });
      }

      // Navigation Active State
      $(window).on("scroll", function (event) {
        var st = $(this).scrollTop();
        $(".section").each(function () {
          var offTop = $(this).offset().top - 10;
          var offBottom = $(this).offset().top + $(this).outerHeight();
          if (st > offTop && st < offBottom) {
            if ($(this).attr("id")) {
              $(".main-nav li").removeClass("active");
              var nav = ".main-nav a[href='#" + $(this).attr("id") + "']";
              $(nav).parent().addClass("active");
            }
          }
        });
      });
    },

    plugins: function () {},
  },
};

$(document).ready(function () {
  engineUtils.init();
});
